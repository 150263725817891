import React from "react";
import Layout from "../components/layout";
import Head from "../components/head";
const book_review = () => {
  return (
    <Layout>
      <Head title="faiz's book review" />
      <div>Comming Soon!</div>
    </Layout>
  );
};

export default book_review;
